<template>
  <b-overlay :show="isSubmitting" rounded="sm">
    <b-container class="col">
      <b-card>
        <div class="d-flex">
          <h5>Pickup Address:</h5>
          <h5 class="redtext" v-if="pickUp.address_line_2">
            {{ pickUp.address_unit }}, {{ pickUp.address_line_1 }},
            {{ pickUp.address_line_2 }}, {{ pickUp.address_area }},
            {{ pickUp.address_postcode }}, {{ pickUp.address_state }},
            {{ pickUp.address_country }}
          </h5>
          <h5 class="redtext" v-else>{{ pickUp.address_line_1 }}</h5>
        </div>
        <div class="d-flex justify-content-end">
          <b-button
            variant="primary"
            href="https://minidc-live-spaces.sgp1.cdn.digitaloceanspaces.com/order_template/DistributorTemplate2024.xlsx"
            >Download Distributor Template</b-button
          >
        </div>
        <validation-observer ref="simpleRules">
          <b-row class="mt-2">
            <b-col>
              <b-form-group label="PICKUP">
                <validation-provider
                  #default="{ errors }"
                  name="pickup address"
                  rules="required"
                >
                  <select
                    class="form-control form-select rounded"
                    v-model="pickUp"
                  >
                    <option value="" disabled selected>Pickup</option>
                    <option
                      v-for="(origin, index) in origin"
                      :key="index"
                      :value="origin"
                    >
                      {{ origin.first_name }} {{ origin.last_name }}
                    </option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="PICKUP DATE">
                <validation-provider
                  #default="{ errors }"
                  name="pickup date"
                  rules="required"
                >
                  <flat-pickr
                    class="form-control"
                    v-model="form.pickup_date"
                    placeholder="Choose Date"
                    readonly="readonly"
                    :config="{
                      minDate: 'today',
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="PICKUP TIME">
                <validation-provider
                  #default="{ errors }"
                  name="pickup time"
                  rules="required"
                >
                  <flat-pickr
                    class="form-control"
                    v-model="form.pickup_time"
                    placeholder="Choose Time"
                    readonly="readonly"
                    :config="{
                      enableTime: true,
                      noCalendar: true,
                      minTime: '08:00',
                      maxTime: '17:00',
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="d-flex align-items-center justify-content-center">
              <b-form-group label="SAME DAY DELIVERY">
                <validation-provider
                  #default="{ errors }"
                  name="Unique order"
                  rules="required"
                  class="w-100"
                >
                  <b-form-radio-group
                    v-model="isDeliverToday"
                    :options="yesno"
                    value-field="value"
                    text-field="title"
                    disabled-field="notEnabled"
                  ></b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="!isDeliverToday">
              <b-form-group label="DELIVERY DATE">
                <validation-provider
                  #default="{ errors }"
                  name="delivery date"
                  rules="required"
                >
                  <flat-pickr
                    class="form-control"
                    v-model="form.delivery_date"
                    placeholder="Choose Date"
                    readonly="readonly"
                    :config="{
                      minDate: new Date(
                        new Date(form.pickup_date).getTime() + 86400000
                      ),
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="!isDeliverToday">
              <b-form-group label="DELIVERY TIME">
                <validation-provider
                  #default="{ errors }"
                  name="delivery time"
                  rules="required"
                >
                  <flat-pickr
                    class="form-control"
                    v-model="form.delivery_time"
                    placeholder="Choose Time"
                    readonly="readonly"
                    :config="{
                      enableTime: true,
                      noCalendar: true,
                      minTime: '08:00',
                      maxTime: '17:00',
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
          <b-row class="mt-2">
            <b-col>
              <b-form-group label="BRANCH DESTINATION">
                <validation-provider
                  #default="{ errors }"
                  name="branch destination"
                  rules="required"
                >
                  <select
                    class="form-control form-select rounded"
                    v-model="form.branch_destination"
                  >
                    <option value="" disabled selected>
                      Branch Destination
                    </option>
                    <option
                      v-for="(branch, index) in branch"
                      :key="index"
                      :value="branch.id"
                    >
                      {{ branch.address }}
                    </option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="IMPORT DISTRIBUTOR SHEET (.xlsx)">
                <b-form-file
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept=".xls,.xlsx"
                  @change="convertSheet($event)"
                  id="input"
                ></b-form-file>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
        <MyTable :columns="columns" :rows="rows" :is-loading="isLoading">
        </MyTable>
      </b-card>
      <div class="d-flex justify-content-center align-items-center mt-5">
        <b-button
          @click="onSubmit"
          variant="gradient-primary"
          class="box-shadow-1 px-5 w-25 d-flex justify-content-center"
          >Submit</b-button
        >
      </div>
    </b-container>
    <template #overlay>
      <div class="text-center">
        <b-spinner
          style="width: 3rem; height: 3rem"
          label="Large Spinner"
          variant="primary"
        ></b-spinner>
        <h3 class="mt-3">Calculating your order. Please wait...</h3>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import { required } from "@validations";
import readXlsxFile from "read-excel-file";
import flatPickr from "vue-flatpickr-component";
import MyTable from "@/views/components/MyTable.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    MyTable,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      isSubmitting: false,
      pickUp: "",
      isLoading: false,
      isDeliverToday: true,
      fetchPrice: [
        {
          sender_postcode: "",
          sender_state: "",
          recipient_postcode: "",
          recipient_state: "",
          order_type: "",
          size: "",
          weight: "",
          quantity: "",
        },
      ],
      form: {
        delivery_time: "",
        delivery_date: "",
        order_type: "Distributor",
        branch_destination: "",
        pickup_id: "",
        deliveries: [],
        pickup_date: "",
        pickup_time: "",
      },
      origin: [],
      branch: [],
      rows: [],
      columns: [
        {
          label: "Remarks",
          field: "remarks",
        },
        {
          label: "Name",
          field: "first_name",
        },
        {
          label: "State",
          field: "state",
        },
        {
          label: "Address",
          field: "address",
        },
        {
          label: "SKU",
          field: "sku",
        },
        {
          label: "Quantity",
          field: "quantity",
        },
        {
          label: "Weight",
          field: "weight",
        },
        {
          label: "Size",
          field: "size",
        },
      ],
      yesno: [
        { title: "YES", value: true },
        { title: "NO", value: false },
      ],
    };
  },
  created() {
    if (this.$route.params.data) {
      this.form = this.$route.params.data;
      this.pickUp = this.$route.params.pickUp;
      this.rows = this.form.deliveries;
    }
  },
  mounted() {
    this.$http.get(`/location/show`).then((response) => {
      this.origin = response.data.data;
    });
    console.log(this.origin);
    this.$http.get(`branch`).then((response) => {
      this.branch = response.data.data;
    });
    console.log(this.branch);
  },
  methods: {
    onSubmit() {
      if (this.rows.length) {
        if (this.isDeliverToday) {
          this.form.delivery_date = this.form.pickup_date;
          this.form.delivery_time = this.form.pickup_time;
        }
        if (this.form.delivery_date >= this.form.pickup_date) {
          this.$refs.simpleRules.validate().then((success) => {
            if (success) {
              this.isSubmitting = true;
              const form = {
                start_state: this.pickUp.address_state,
                rows: this.rows,
              };
              this.$http.post(`price_compute`, form).then((response) => {
                if (response.data.status) {
                  this.form.deliveries = response.data.rows;
                  this.form.pickup_id = this.pickUp.id
                  this.form.start_state = this.pickUp.address_state;
                  this.$router.push({
                    name: "Distributor-Bulk-delivery-cart",
                    params: { data: this.form, pickUp: this.pickUp },
                  });
                } else {
                  var errors = response.data.errors;
                  for (var a in errors) {
                    var error = errors[a];
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: error,
                        icon: "XIcon",
                        variant: "warning",
                      },
                    });
                  }
                }
                this.isSubmitting = false;
              });
            }
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Delivery Date must not be earlier than Pickup Date!",
              icon: "XIcon",
              variant: "warning",
            },
          });
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Please import distributor sheet",
            icon: "XIcon",
            variant: "warning",
          },
        });
      }
    },
    convertSheet(e) {
      this.rows.length = 0;
      readXlsxFile(e.target.files[0]).then((rows) => {
        let allRowsValid = true;
        const tempRows = [];
        for (let x = 1; x < rows.length; x++) {
          const isAnyColumnBlank = rows[x].some(
            (column) => column === "" || column == null
          );
          if (isAnyColumnBlank) {
            this.$toast.error(
              `Operation cancelled. Row ${x} contains an empty field. Please fill it out and try again.`
            );
            allRowsValid = false;
            break;
          } else {
            tempRows.push({
              remarks: rows[x][0],
              first_name: rows[x][1],
              contact: rows[x][2],
              state: rows[x][3],
              address: rows[x][4],
              sku: rows[x][5],
              quantity: rows[x][6],
              weight: rows[x][7],
              size: rows[x][8],
            });
          }
        }
        if (allRowsValid) {
          this.rows = tempRows;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/scss/style.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.redtext {
  color: rgb(216, 54, 54);
  font-weight: bold;
}

.custom-file-label {
  font-size: 14px;
}
</style>
