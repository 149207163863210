var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.isSubmitting,"rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"label":"Large Spinner","variant":"primary"}}),_c('h3',{staticClass:"mt-3"},[_vm._v("Calculating your order. Please wait...")])],1)]},proxy:true}])},[_c('b-container',{staticClass:"col"},[_c('b-card',[_c('div',{staticClass:"d-flex"},[_c('h5',[_vm._v("Pickup Address:")]),(_vm.pickUp.address_line_2)?_c('h5',{staticClass:"redtext"},[_vm._v(" "+_vm._s(_vm.pickUp.address_unit)+", "+_vm._s(_vm.pickUp.address_line_1)+", "+_vm._s(_vm.pickUp.address_line_2)+", "+_vm._s(_vm.pickUp.address_area)+", "+_vm._s(_vm.pickUp.address_postcode)+", "+_vm._s(_vm.pickUp.address_state)+", "+_vm._s(_vm.pickUp.address_country)+" ")]):_c('h5',{staticClass:"redtext"},[_vm._v(_vm._s(_vm.pickUp.address_line_1))])]),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"primary","href":"https://minidc-live-spaces.sgp1.cdn.digitaloceanspaces.com/order_template/DistributorTemplate2024.xlsx"}},[_vm._v("Download Distributor Template")])],1),_c('validation-observer',{ref:"simpleRules"},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('b-form-group',{attrs:{"label":"PICKUP"}},[_c('validation-provider',{attrs:{"name":"pickup address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.pickUp),expression:"pickUp"}],staticClass:"form-control form-select rounded",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.pickUp=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v("Pickup")]),_vm._l((_vm.origin),function(origin,index){return _c('option',{key:index,domProps:{"value":origin}},[_vm._v(" "+_vm._s(origin.first_name)+" "+_vm._s(origin.last_name)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"PICKUP DATE"}},[_c('validation-provider',{attrs:{"name":"pickup date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Choose Date","readonly":"readonly","config":{
                    minDate: 'today',
                  }},model:{value:(_vm.form.pickup_date),callback:function ($$v) {_vm.$set(_vm.form, "pickup_date", $$v)},expression:"form.pickup_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"PICKUP TIME"}},[_c('validation-provider',{attrs:{"name":"pickup time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Choose Time","readonly":"readonly","config":{
                    enableTime: true,
                    noCalendar: true,
                    minTime: '08:00',
                    maxTime: '17:00',
                  }},model:{value:(_vm.form.pickup_time),callback:function ($$v) {_vm.$set(_vm.form, "pickup_time", $$v)},expression:"form.pickup_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-form-group',{attrs:{"label":"SAME DAY DELIVERY"}},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":"Unique order","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.yesno,"value-field":"value","text-field":"title","disabled-field":"notEnabled"},model:{value:(_vm.isDeliverToday),callback:function ($$v) {_vm.isDeliverToday=$$v},expression:"isDeliverToday"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(!_vm.isDeliverToday)?_c('b-col',[_c('b-form-group',{attrs:{"label":"DELIVERY DATE"}},[_c('validation-provider',{attrs:{"name":"delivery date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Choose Date","readonly":"readonly","config":{
                    minDate: new Date(
                      new Date(_vm.form.pickup_date).getTime() + 86400000
                    ),
                  }},model:{value:(_vm.form.delivery_date),callback:function ($$v) {_vm.$set(_vm.form, "delivery_date", $$v)},expression:"form.delivery_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3336969889)})],1)],1):_vm._e(),(!_vm.isDeliverToday)?_c('b-col',[_c('b-form-group',{attrs:{"label":"DELIVERY TIME"}},[_c('validation-provider',{attrs:{"name":"delivery time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Choose Time","readonly":"readonly","config":{
                    enableTime: true,
                    noCalendar: true,
                    minTime: '08:00',
                    maxTime: '17:00',
                  }},model:{value:(_vm.form.delivery_time),callback:function ($$v) {_vm.$set(_vm.form, "delivery_time", $$v)},expression:"form.delivery_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1669600382)})],1)],1):_vm._e()],1),_c('hr'),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('b-form-group',{attrs:{"label":"BRANCH DESTINATION"}},[_c('validation-provider',{attrs:{"name":"branch destination","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.branch_destination),expression:"form.branch_destination"}],staticClass:"form-control form-select rounded",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "branch_destination", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v(" Branch Destination ")]),_vm._l((_vm.branch),function(branch,index){return _c('option',{key:index,domProps:{"value":branch.id}},[_vm._v(" "+_vm._s(branch.address)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"IMPORT DISTRIBUTOR SHEET (.xlsx)"}},[_c('b-form-file',{attrs:{"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here...","accept":".xls,.xlsx","id":"input"},on:{"change":function($event){return _vm.convertSheet($event)}}})],1)],1)],1)],1),_c('MyTable',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"is-loading":_vm.isLoading}})],1),_c('div',{staticClass:"d-flex justify-content-center align-items-center mt-5"},[_c('b-button',{staticClass:"box-shadow-1 px-5 w-25 d-flex justify-content-center",attrs:{"variant":"gradient-primary"},on:{"click":_vm.onSubmit}},[_vm._v("Submit")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }